import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { config } from '@/api/config';
import _ from 'lodash';
import VueGtag from 'vue-gtag';
import Flag from 'vue-flagpack';

Vue.config.productionTip = false;

async function handleGlobalError(err: any) {
  try {
    if (err.isHandled) return;
    err.version = config.Version;
    err.env = config.Env;
    if (config.IsLocalEnv) {
      console.error(`ERROR:`, err);
    }
    if (!config.IsProductionEnv) {
      // todo send message to backend
    }
    if (!config.IsLocalEnv) {
      const message =
        _.isObjectLike(err) && err.response && err.response.data
          ? `[${err.response.status}]: ${err.response.data}`
          : err.toString
          ? err.toString()
          : 'UI error';
      console.log(message);
      // await wpsClient
      //     .logUIErr(
      //         {
      //           severity: 'error',
      //           message: message, // err.toString ? err.toString() : 'UI error';
      //         },
      //         err,
      //     )
      //     .toPromise();
    }
  } catch {
    if (!config.IsProductionEnv) {
      // todo send message to backend
    }
  }
}

Vue.config.errorHandler = function (err, vm, info) {
  const e = err as any;
  e.vueinfo = info;
  handleGlobalError(e);
};

window.onerror = function (msg, url, line, col, err) {
  handleGlobalError(err);
};

/** Google Analytics **/
Vue.use(
  VueGtag,
  {
    appName: 'Go City',
    config: { id: config.GoogleAnalyticsTagId },
    pageTrackerScreenviewEnabled: true,
    enabled: !config.IsLocalEnv,
  },
  router,
);

Vue.use(Flag, {
  name: 'vue-flag',
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
