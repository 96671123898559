import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import NotFoundView from '@/views/NotFoundView.vue';
import { constants } from '@/helpers/constants';
import ValidationView from '@/views/ValidationView.vue';
import HelpView from '@/views/HelpView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: constants.routes.paths.HOME,
    name: constants.routes.names.HOME,
    component: HelpView,
  },
  {
    path: constants.routes.paths.PASS_VALIDATION,
    name: constants.routes.names.PASS_VALIDATION,
    component: ValidationView,
  },
  {
    path: constants.routes.paths.HELP,
    name: constants.routes.names.HELP,
    component: HelpView,
  },
  {
    path: constants.routes.paths.BOOKING_CREATE,
    name: constants.routes.names.BOOKING_CREATE,
    component: () => import(/* webpackChunkName: "booking-create" */ '../views/BookingCreateView.vue'),
  },
  {
    path: constants.routes.paths.BOOKING_DETAIL,
    name: constants.routes.names.BOOKING_DETAIL,
    component: () => import(/* webpackChunkName: "booking-detail" */ '../views/BookingDetailsView.vue'),
  },
  {
    path: constants.routes.paths.BOOKING_LIST,
    name: constants.routes.names.BOOKING_LIST,
    component: () => import(/* webpackChunkName: "booking-list" */ '../views/BookingListView.vue'),
  },
  {
    path: constants.routes.paths.BOOKING_DETAIL_BY_PASS,
    name: constants.routes.names.BOOKING_DETAIL_BY_PASS,
    component: () => import(/* webpackChunkName: "booking-detail" */ '../views/BookingDetailByPassView.vue'),
  },
  {
    path: constants.routes.paths.PASS_CONFIRMATION_EMAIL,
    name: constants.routes.names.PASS_CONFIRMATION_EMAIL,
    component: () => import(/* webpackChunkName: "booking-detail" */ '../views/ConfirmationView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: constants.routes.names.NOT_FOUND,
    component: NotFoundView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
