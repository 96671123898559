<template>
  <v-alert text outlined type="error" icon="mdi-alert-circle" elevation="1">
    <v-row>
      <v-col class="standard-text-color" style="white-space: pre-line">{{ message }}</v-col>
    </v-row>
    <v-row>
      <v-col class="text-align-right">
        <v-btn class="mr-3" color="error" text @click="goToHelp">Contact</v-btn>
        <v-btn v-if="IsPassValidationPage && ShowContinueButtonPassValidation" color="error" text @click="proceed"
          >Continue</v-btn
        >
        <v-btn v-if="IsBookingConfirmationPage" color="error" text @click="dismiss">Dismiss</v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { appModule } from '@/store/modules/moduleApp';
import { constants } from '@/helpers/constants';
import { passValidationModule } from '@/store/modules/modulePassValidation';
import { bookingCreateModule } from '@/store/modules/moduleBookingCreate';
import { PassValidationResponse, ValidPass } from '@/models';

@Component({
  components: {},
})
export default class GoCityAlertBanner extends Vue {
  @Prop({ type: String, default: '', required: true })
  public message!: string;

  goToHelp() {
    this.dismiss();
    if (this.$route.name !== constants.routes.names.HELP) {
      this.$router.push({ name: constants.routes.names.HELP }).catch();
    }
  }

  proceed() {
    this.dismiss();
    if (this.PassResultCreationList.length > 0) {
      const validPasses = [] as Array<ValidPass>;
      this.PassResultCreationList.forEach((p) => {
        validPasses.push({ passCode: p.code, travelerType: p.passType, displayTravelerType: p.passType });
      });
      bookingCreateModule.setValidPassCodes(validPasses);
      this.goToBookingCreate();
    }
  }

  dismiss() {
    appModule.setAlertBannerActive(false);
    appModule.setAlertBannerMessage('');
  }

  goToBookingCreate() {
    if (this.$route.name !== constants.routes.names.BOOKING_CREATE) {
      this.$router.push({ name: constants.routes.names.BOOKING_CREATE }).catch();
    }
  }

  goToBookingDetails() {
    if (this.$route.name !== constants.routes.names.BOOKING_DETAIL) {
      this.$router.push({ name: constants.routes.names.BOOKING_DETAIL }).catch();
    }
  }

  get ShowContinueButtonPassValidation(): boolean {
    return passValidationModule.PassResultList.length > passValidationModule.InvalidPassCount;
  }

  get PassResultCreationList(): Array<PassValidationResponse> {
    return passValidationModule.PassResultCreationList;
  }

  get IsPassValidationPage() {
    return this.$route.name === constants.routes.names.PASS_VALIDATION;
  }

  get IsBookingCreatePage() {
    return this.$route.name === constants.routes.names.BOOKING_CREATE;
  }

  get IsBookingConfirmationPage() {
    return this.$route.name === constants.routes.names.PASS_CONFIRMATION_EMAIL;
  }
}
</script>
