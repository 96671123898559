import { HTTPLogRecord, LogLevel, LogRecord } from '@/logger/logging.models';

/**
 * Logger - logging class for the UI
 *
 * Example Usage:
 *   const logger = new Logger();
 *   logger.info('This is an information message.', { key: 'value' });
 *   logger.warn('This is a warning message.');
 *   logger.error('This is an error message.');
 */
export class Logger {
  private log(level: LogLevel, message: string, metadata?: Record<string, any>): void {
    const logRecord: LogRecord = {
      severity: level,
      timestamp: new Date().toISOString(),
      message: message,
    };
    if (metadata) {
      logRecord.metadata = metadata;
    }
    console.log(JSON.stringify(logRecord));
  }

  debug(message: string, metadata?: Record<string, any>): void {
    this.log(LogLevel.DEBUG, message, metadata);
  }

  info(message: string, metadata?: Record<string, any>): void {
    this.log(LogLevel.INFO, message, metadata);
  }

  warn(message: string, metadata?: Record<string, any>): void {
    this.log(LogLevel.WARN, message, metadata);
  }

  error(message: string, metadata?: Record<string, any>): void {
    this.log(LogLevel.ERROR, message, metadata);
  }

  http(
    layer: string,
    url: string,
    host: string,
    method: string,
    statusCode: number,
    duration: string,
    requestBody: string,
    responseBody: string,
    verbose: boolean,
  ) {
    const logRecord: HTTPLogRecord = {
      severity: statusCode >= 400 ? 'ERROR' : 'INFO',
      timestamp: new Date().toISOString(),
      message: `[${layer}] HTTP ${statusCode} - ${method.toUpperCase()} ${url}`,
      method: method,
      host: host,
      url: url,
      statusCode: statusCode,
      duration: duration,
    };

    /** only log the full request and response if there was an error or verbose logging is enabled */
    if (verbose || statusCode >= 400) {
      logRecord.requestBody = this.trimBody(requestBody);
      logRecord.responseBody = this.trimBody(responseBody);
    }
    console.log(JSON.stringify(logRecord));
  }

  /**
   * trimBody trims the body data to 4500 characters to be under the GCP limit
   * @param str
   */
  private trimBody(str: string): string {
    const maxLength = 45000;
    if (str.length <= maxLength) {
      return str;
    }
    return str.substring(0, maxLength - 3) + '...';
  }
}
