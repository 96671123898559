import axios, { AxiosInstance } from 'axios';
import { AxiosErrorWithResponse, intercept } from '@/api/axiosInterceptors';
import { config } from '@/api/config';
import { OppApiError } from '@/api/errorHandlers';

export interface VoucherPDFRQ {
  bookingID?: string;
  templateID?: string;
  format?: string;
  emailOverrides?: string[];
  parameters?: Parameters;
}

export interface Parameters {
  ContactPhone?: string;
  ResellerName?: string;
  AgentFacing?: boolean;
  WithRetailPricing?: boolean;
  WithNetPricing?: boolean;
  WithOriginalPricing?: boolean;
  MarketRegion?: string;
  DiscountGroup?: string;
  TicketStartDate?: string;
  TicketEndDate?: string;
  TicketValidityDays?: number;
  PickupInstructions?: string;
  AgentName?: string;
}

const voucherApi = '/get-pdf';
const voucherEmailApi = '/send-email';

export class VoucherClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.VoucherApiUrl,
      timeout: parseInt(config.ApiTimeout) || 1000 * 60 * 5, // todo replace with config variable
      withCredentials: true,
      xsrfCookieName: 'XSRF-TOKEN',
      xsrfHeaderName: 'X-CSRF-Token',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // this.httpClient.interceptors.request.use(intercept.Request);
    this.httpClient.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      },
    );

    this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(VoucherClient.ErrorResponse));
  }

  public async getVoucher(req: VoucherPDFRQ, s: string): Promise<string> {
    return this.httpClient.post<string>(`${voucherApi}?s=${s}`, req).then((res: any) => res.data);
  }

  public async getVoucherImage(value: string, s: string): Promise<string> {
    return this.httpClient.post<string>(`/getBarcode?value=${value}&s=${s}`, {}).then((res: any) => res.data);
  }

  public async sendVoucher(req: VoucherPDFRQ, s: string): Promise<any> {
    return this.httpClient.post<any>(`${voucherEmailApi}?s=${s}`, req).then((res: any) => res.data);
  }

  private static ErrorResponse(err: AxiosErrorWithResponse) {
    // if (err.response?.status >= 400) {
    //   console.clear();
    // }

    if (err.response?.status === 401) {
      return VoucherClient.on401(err);
    }

    if (err.response.status >= 400 && err.response.status < 500) {
      const wpsErr = err.response.data as OppApiError;
      // if (err.response.status === 403) {
      //   appModule.addMessageError(codes.GENERAL_MESSAGE_FORBIDDEN);
      // }
      return Promise.reject({
        status: err.response.status,
        wpsErr,
        ...err,
      });
    }

    // not 4xx
    return Promise.reject({
      status: err.response.status,
      ...err,
    });
  }

  private static on401(err: any): Promise<any> {
    return Promise.reject(err);
  }

  public handle401(on401: (err: any) => void) {
    VoucherClient.on401 = (err: any) => {
      on401(err);
      return Promise.reject(err);
    };
  }
}

const voucherClient = new VoucherClient();
export default voucherClient;
