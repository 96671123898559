export const requiredFieldRules: Array<any> = [
  (v: string) => !!v || 'This is required!',
  (v: Array<any>) => (v && v.toString().trim().length > 0) || 'This is required!',
];

export const emailRules: Array<any> = [
  (v: string) => !!v || 'E-mail Address is required',
  (v: string) => /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/.test(v) || 'E-mail must be valid',
  // (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
];
export const phoneRules: Array<any> = [
  (v: string) => !!v || 'Phone is required',
  (v: string) => /^[0-9]{3,14}$/.test(v) || 'Please enter valid phone number. Eg: 1234567898',
  // \+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}
  // (v: string) => /^\+\d[\d\s\\(\\)-]+$/.test(v) || 'Please enter valid phone number. Eg: +12345678981',
  // (v: string) =>
  //   /\(\d{3}\)\s\d{3}-\d{4}/.test(v) ||
  //   /\d{10}/.test(v) ||
  //   /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s.]?[(]?[0-9]{1,3}[)]?([-\s.]?[0-9]{3})([-\s.]?[0-9]{3,4})/.test(
  //     v,
  //   ) ||
  //   'Phone Number must be valid',
];
