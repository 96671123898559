<template>
  <v-app>
    <v-app-bar app>
      <v-btn v-if="!IsPassValidation" @click="goHome" color="primary" icon>
        <v-icon>mdi-chevron-left</v-icon>
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>
      <v-spacer></v-spacer>

      <v-toolbar-title>
        <v-img
          alt="GoCity Logo"
          class="cursor-pointer mr-2"
          contain
          src="~@/assets/Go_City_Logo.png"
          transition="fade-transition"
          width="150"
          @click="goHome"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="goToHelp" icon>
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main class="app-size" v-bind:class="{ 'width-880': $vuetify.breakpoint.mdAndUp }">
      <v-container>
        <v-row>
          <v-col cols="12" class="app-spacing">
            <go-city-alert-banner v-show="AlertBannerActive" :message="AlertBannerMessage"></go-city-alert-banner>
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import GoCityAlertBanner from '@/components/common/GoCityAlertBanner.vue';
import { appModule } from '@/store/modules/moduleApp';
import { constants } from '@/helpers/constants';
@Component({
  components: {
    GoCityAlertBanner,
  },
})
export default class App extends Vue {
  private attractionStorageKey = constants.app.ATTRACTION_ID;

  mounted() {
    this.getAttractionId();
  }

  getAttractionId() {
    const attractionId = localStorage.getItem(this.attractionStorageKey);
    if (attractionId) {
      appModule.setVenueId(attractionId);
    }
  }

  goHome() {
    appModule.dismissAlertBanner();
    this.getAttractionId();
    if (this.$route.name === constants.routes.names.PASS_VALIDATION && this.HasVenueId) {
      return;
    }

    if (this.$route.name !== constants.routes.names.PASS_VALIDATION && this.HasVenueId) {
      const venueId = appModule.VenueId;
      this.$router.push({ name: constants.routes.names.PASS_VALIDATION, params: { venueId: venueId } }).catch();
    } else if (
      this.$route.name !== constants.routes.names.HOME &&
      this.$route.name !== constants.routes.names.NOT_FOUND
    ) {
      this.$router.push({ name: constants.routes.names.HOME }).catch();
    }
  }

  goToHelp() {
    if (this.$route.name !== constants.routes.names.HELP) {
      this.$router.push({ name: constants.routes.names.HELP }).catch();
    }
  }

  goBack() {
    this.$router.go(-1);
  }

  get IsPassValidation() {
    return this.$route.name === constants.routes.names.PASS_VALIDATION;
  }

  get AlertBannerActive() {
    return appModule.AlertBannerActive;
  }

  get AlertBannerMessage() {
    return appModule.AlertBannerMessage;
  }

  get HasVenueId() {
    return appModule.HasVenueId;
  }
}
</script>

<style lang="scss">
@import 'styles/styles';
</style>
