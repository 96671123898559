import store from '@/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { BookingDetail, VoucherRequest } from '@/models';
import voucherClient from '@/api/voucherClient';
import { appModule } from '@/store/modules/moduleApp';

@Module({
  dynamic: true,
  store,
  name: 'rdm-gc-booking-list',
  namespaced: true,
})
class ModuleBookingList extends VuexModule {
  private bookings = [] as Array<BookingDetail>;
  private selectedBooking = {} as BookingDetail;
  private suppliers = [] as Array<any>;
  private selectedSupplier = {} as any;
  private products = [] as Array<any>;
  private selectedProduct = {} as any;
  private validPassCodes = [] as Array<string>;
  private voucherPdfs = new Map<string, string>();
  private loadingPDF = false;
  private loadingEmail = false;
  private loading = false;

  get Bookings() {
    return this.bookings;
  }

  get SelectedBooking() {
    return this.selectedBooking;
  }

  get Suppliers() {
    return this.suppliers;
  }

  get SelectedSupplier() {
    return this.selectedSupplier;
  }

  get Products() {
    return this.products;
  }

  get SelectedProduct() {
    return this.selectedProduct;
  }

  get ValidPassCodes() {
    return this.validPassCodes;
  }

  get Loading() {
    return this.loading;
  }

  get LoadingPDF(): boolean {
    return this.loadingPDF;
  }

  get LoadingEmail(): boolean {
    return this.loadingEmail;
  }

  get PassVouchers(): Map<string, string> {
    return this.voucherPdfs;
  }

  @Mutation
  setLoadingPDF(loadingPDF: boolean) {
    this.loadingPDF = loadingPDF;
  }

  @Mutation
  setLoadingEmail(loading: boolean) {
    this.loadingEmail = loading;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setVoucherPdf(_: { passID: string; data: string }) {
    const pdf = _.data != '' ? 'data:application/pdf;base64,' + _.data : '';
    this.voucherPdfs.set(_.passID, pdf);
  }

  @Mutation
  setValidPassCodes(passCodes: Array<string>) {
    this.validPassCodes = passCodes;
  }

  @Mutation
  setBookings(bookings: Array<BookingDetail>) {
    this.bookings = bookings;
  }

  @Mutation
  setSelectedBooking(booking: BookingDetail) {
    this.selectedBooking = booking;
  }

  @Mutation
  setSuppliers(suppliers: Array<any>) {
    this.suppliers = suppliers;
  }

  @Mutation
  setSelectedSupplier(supplier: any) {
    this.selectedSupplier = supplier;
  }

  @Mutation
  setProducts(products: Array<any>) {
    this.products = products;
  }

  @Mutation
  setSelectedProduct(product: any) {
    this.selectedProduct = product;
  }

  @Action
  addBooking(booking: BookingDetail) {
    this.Bookings.push(booking);
  }

  @Action
  removeBooking(booking: BookingDetail) {
    const i = this.Bookings.findIndex((b) => b.bookingId === booking.bookingId && b.passId === booking.passId);
    if (i > -1) {
      this.Bookings.splice(i, 1);
    }
  }

  @Action
  async resendConfirmation(req: VoucherRequest) {
    const bookingId = req.bookingId;
    await Promise.resolve(this.setLoadingEmail(true))
      .then(() => {
        const venueId = appModule.VenueId;
        return voucherClient.sendVoucher(
          {
            bookingID: bookingId,
            templateID: 'gocity_booking_confirmation',
            format: 'email',
          },
          venueId,
        );
      })
      .finally(() => this.setLoadingEmail(false));
  }

  @Action
  chooseBookingByPassCode(passCode: string) {
    const i = this.bookings.findIndex((b) => b.passId === passCode);
    if (i > -1) {
      this.setSelectedBooking(this.bookings[i]);
      console.log(this.Suppliers);
      const iSupplier = this.Suppliers.findIndex((s) => s.id === this.bookings[i].supplierId);
      if (iSupplier > -1) {
        this.setSelectedSupplier(this.Suppliers[iSupplier]);
      }
      const iProduct = this.Products.findIndex((p) => p.id === this.bookings[i].productId);
      if (iProduct > -1) {
        this.setSelectedProduct(this.Products[iProduct]);
      }
    }
  }

  @Action
  appendToSuppliers(supplier: any) {
    this.suppliers.push(supplier);
  }

  @Action
  appendToProducts(product: any) {
    this.products.push(product);
  }

  @Action
  clearState() {
    this.setValidPassCodes([]);
    this.setBookings([]);
    this.setSuppliers([]);
    this.setSelectedSupplier({});
    this.setProducts([]);
    this.setSelectedProduct({});
    this.setSelectedBooking({} as BookingDetail);
    this.setLoading(false);
  }
}

export const bookingListModule = getModule(ModuleBookingList, store);
