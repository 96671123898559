import store from '@/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { PassValidationResponse } from '@/models';

@Module({
  dynamic: true,
  store,
  name: 'rdm-gc-pass-validate',
  namespaced: true,
})
class ModulePassValidation extends VuexModule {
  private loading = false;
  private pageLoading = false;
  private guestCount = 1;
  private passList = [''];
  private passResultList = [] as Array<PassValidationResponse>;
  private hasInvalidPass = false;
  private invalidPassCount = 0;

  get Loading() {
    return this.loading;
  }

  get PageLoading() {
    return this.pageLoading;
  }

  get GuestCount() {
    return this.guestCount;
  }

  get PassList() {
    return this.passList;
  }

  get PassResultList() {
    return this.passResultList;
  }

  get PassResultCreationList() {
    return this.passResultList.filter((p) => p.status === 200);
  }

  get PassResultInvalidList() {
    return this.passResultList.filter((p) => p.status >= 300);
  }

  get HasInvalidPass() {
    return this.hasInvalidPass;
  }

  get InvalidPassCount() {
    return this.invalidPassCount;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setPageLoading(loading: boolean) {
    this.pageLoading = loading;
  }

  @Mutation
  setGuestCount(count: number) {
    this.guestCount = count;
  }

  @Mutation
  setPassList(passList: Array<string>) {
    this.passList = passList;
  }

  @Mutation
  setPassResponseList(passResultList: Array<PassValidationResponse>) {
    this.passResultList = passResultList;
  }

  @Mutation
  setHasInvalidPass(invalidPass: boolean) {
    this.hasInvalidPass = invalidPass;
  }

  @Mutation
  setInvalidPassCount(count: number) {
    this.invalidPassCount = count;
  }

  @Action
  addGuestCount() {
    this.setGuestCount(this.guestCount + 1);
  }
  @Action
  removeGuestCount() {
    this.setGuestCount(this.guestCount - 1);
  }

  @Action
  addPassToList(pass: string) {
    this.passList.push(pass);
  }

  @Action
  removeItemFromPassList(index: number) {
    this.passList.splice(index, 1);
  }

  @Action
  validatePassResults() {
    let isInvalid = false;
    let invalidCount = 0;
    this.PassResultList.forEach((p) => {
      if (p.status >= 300) {
        isInvalid = true;
        invalidCount += 1;
      }
    });
    this.setHasInvalidPass(isInvalid);
    this.setInvalidPassCount(invalidCount);
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
    this.setPageLoading(false);
    this.setGuestCount(1);
    this.setPassList(['']);
    this.setPassResponseList([]);
    this.setHasInvalidPass(false);
    this.setInvalidPassCount(0);
  }
}

export const passValidationModule = getModule(ModulePassValidation, store);
