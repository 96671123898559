export const constants = {
  app: {
    MAX_GUEST_PASS_COUNT: 9,
    ATTRACTION_ID: 'attraction_id',
  },
  messages: {
    ALERT_INCORRECT_ID:
      'An ID you entered was incorrect. Please try again or contact us for assistance. You may continue with valid IDs.',
    ALERT_SOME_INVALID_PASSES: 'One or more of your passes are no longer valid.',
    ALERT_REMOVE_INVALID_PASSES:
      'Please remove any invalid passes to proceed. If you are still having issues please contact us for assistance.',
    ALERT_ALL_INCORRECT_ID: 'The IDs you entered were incorrect. Please try again or contact us for assistance.',
    ALERT_INVALID_LINK: 'There was a problem with the link. Please try again or contact us for assistance.',
    HOME_PAGE_TITLE: 'Redeem Your Tickets',
    HOME_PAGE_SUBTITLE: 'Enter your pass number from Go City to select your tickets or view an existing reservation.',
    DETAILS_PAGE_TITLE: 'Welcome!',
    DETAILS_PAGE_SUBTITLE: 'Here are your tickets associated with the ID ',
    BOOKING_CREATE_NO_CHILD:
      'There’s only one ticket type available. If your child meets the criteria mentioned in the attraction description above, please book an adult ticket.',
    BOOKING_CREATE_503_ERROR: 'Unable to complete the booking due to a maintenance window, please try again later',
    BOOKING_CREATE_GENERAL_ERROR: 'The booking was not able to be made. Please try again or contact us for assistance.',
    CONFIRMATION_CHECK_SPAM_EMAIL: "Don't see a confirmation email?\nTry checking your spam folder.",
    CONFIRMATION_USE_WITHIN_24_HOURS: 'This ticket must be redeemed within 24 hours',
    NO_TICKETS_AVAILABLE:
      'There are currently no tickets available, this can be due to inclement weather or the attraction being closed.',
  },
  routes: {
    paths: {
      HOME: '/',
      HELP: '/gc/help',
      BOOKING_CREATE: '/booking/create',
      BOOKING_DETAIL: '/booking/detail',
      BOOKING_DETAIL_BY_PASS: '/booking/detail/:passId',
      BOOKING_LIST: '/booking/list',
      PASS_CONFIRMATION_EMAIL: '/booking/confirmation',
      PASS_VALIDATION: '/attractions/:venueId',
    },
    names: {
      HOME: 'home',
      HELP: 'help',
      NOT_FOUND: '404-not-found',
      BOOKING_CREATE: 'booking-create',
      BOOKING_DETAIL: 'booking-detail',
      BOOKING_DETAIL_BY_PASS: 'booking-detail-by-pass',
      BOOKING_LIST: 'booking-list',
      PASS_VALIDATION: 'pass-validation',
      PASS_CONFIRMATION_EMAIL: 'pass-confirmation-email',
    },
  },
  values: {
    CANCELLED: 'CANCELLED',
    OPEN: 'OPEN',
  },
};
