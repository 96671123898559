import { Action, getModule, Module, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { AuditMethod, AuditRecord, BookingDetail, PassValidationResponse, ValidPass } from '@/models';
import bookingClient from '@/api/bookingClient';
import gcClient from '@/api/gcClient';
import { passValidationModule } from '@/store/modules/modulePassValidation';
import { appModule } from '@/store/modules/moduleApp';
import { bookingCreateModule } from '@/store/modules/moduleBookingCreate';
import router from '@/router';
import { constants } from '@/helpers/constants';
import { bookingDetailModule } from '@/store/modules/moduleBookingDetail';
import { bookingListModule } from '@/store/modules/moduleBookingList';
import voucherClient from '@/api/voucherClient';
import _ from 'lodash';
import { bookingConfirmationModule } from '@/store/modules/moduleBookingConfirmation';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function auditLog(before: any, after: any, method: AuditMethod) {
  const audit = {} as AuditRecord;
  audit.dataBefore = before;
  audit.dataAfter = after;
  audit.changeType = method;
  // inventoryClient.createAuditLog(audit).toPromise().then();
}

@Module({
  dynamic: true,
  store,
  name: 'rdm-gc-http',
  namespaced: true,
})
class ModuleHttp extends VuexModule {
  /**
   *  App
   **/
  @Action
  async getPassValid(passes: Array<string>) {
    try {
      passValidationModule.setLoading(true);
      const venueId = appModule.VenueId;
      const promise = gcClient.getPassUsage(passes.toString(), venueId).toPromise();
      await promise.then((data: Array<PassValidationResponse>) => {
        const results = [] as Array<PassValidationResponse>;
        passes.forEach((p) => {
          const i = data.findIndex((d) => d.code === p);
          if (i > -1) {
            results.push(data[i]);
          }
        });
        passValidationModule.setPassResponseList(results);
        passValidationModule.validatePassResults();
        return data;
      });
    } catch (e) {
      console.log('err', e);
      passValidationModule.setLoading(false);
    }
  }

  @Action
  async searchBookings(code: Array<string>) {
    try {
      bookingDetailModule.setLoading(true);
      bookingListModule.setLoading(true);
      bookingConfirmationModule.setLoading(true);
      const venueId = appModule.VenueId;
      const attraction = await bookingClient.attractionName(venueId).toPromise();
      const promise = bookingClient.searchBookings(code.toString(), venueId).toPromise();
      await promise.then((data) => {
        const bookings = [] as Array<BookingDetail>;
        const suppliers = [] as Array<any>;
        const products = [] as Array<any>;
        if (Array.isArray(data)) {
          data.forEach((d) => {
            if (d && Array.isArray(d.bookings)) {
              d.bookings.forEach((b: any) => {
                const item = Array.isArray(b.items) && b.items.length > 0 ? b.items[0] : null;
                const ticket = Array.isArray(b.tickets) && b.tickets.length > 0 ? b.tickets[0] : null;
                const location =
                  item && item.rate && Array.isArray(item.rate.locations) && item.rate.locations.length > 0
                    ? item.rate.locations[0]
                    : null;
                const rateName = item && item.rate && item.rate.name ? item.rate.name : '';
                const supplierReference = b && b.ext && b.ext['supplier.reference'] ? b.ext['supplier.reference'] : '';
                let timestamp = new Date().toISOString();
                if (Array.isArray(b.timeline)) {
                  b.timeline.forEach((t: any) => {
                    if (t.typeOf === constants.values.CANCELLED) {
                      timestamp = t.timestamp;
                    }
                  });
                }
                const instruction = attraction.instruction || '';
                if (b.status === constants.values.OPEN) {
                  bookings.push({
                    bookingId: b.id,
                    supplierId: item?.supplierId,
                    productId: item?.productId,
                    status: b.status,
                    passId: b.resellerBookingRef,
                    firstName: b.customer?.firstName,
                    lastName: b.customer?.lastName,
                    email: b.customer?.email,
                    phone: b.customer?.phone,
                    productName: item?.rate?.name,
                    locationName: location?.name,
                    rateName: rateName,
                    bookingDate: item?.startTime,
                    supplierReference: supplierReference,
                    ticket: ticket,
                    cancelledDate: timestamp,
                    instruction: instruction,
                  });
                }
              });
            }
            if (d && d.supplier && d.supplier.supplier) {
              suppliers.push(d.supplier.supplier);
            }

            if (d && d.products && Array.isArray(d.products.products)) {
              d.products.products.forEach((p: any) => products.push(p));
            }
          });
        }
        bookings.sort((a, b) => {
          const lowerA = a.bookingDate?.toLowerCase() || '';
          const lowerB = b.bookingDate?.toLowerCase() || '';

          if (lowerA < lowerB) {
            return -1;
          }

          if (lowerA > lowerB) {
            return 1;
          }

          return 0;
        });
        bookingListModule.setBookings(bookings);
        bookingListModule.setSuppliers(suppliers);
        bookingListModule.setProducts(products);
        bookingListModule.setLoading(false);
        bookingDetailModule.setBookings(bookings);
        bookingDetailModule.setSuppliers(suppliers);
        bookingDetailModule.setProducts(products);
        bookingDetailModule.setLoading(false);
        bookingConfirmationModule.setBookings(bookings);
        bookingConfirmationModule.setSuppliers(suppliers);
        bookingConfirmationModule.setProducts(products);
      });
    } catch (e) {
      console.log('err', e);
      bookingDetailModule.setLoading(false);
    }
  }

  @Action
  async viewBookingCreate() {
    try {
      bookingCreateModule.setLoading(true);
      const venueId = appModule.VenueId;
      const promise = bookingClient.viewBookingCreate(venueId).toPromise();
      await promise.then((data) => {
        bookingCreateModule.setLoading(false);
        if (!data) {
          router.push({ name: constants.routes.names.HOME }).catch();
          return;
        }

        if (data) {
          bookingCreateModule.setSelectedSupplierProduct(data);
          if (Array.isArray(data.rates)) {
            const sorted = data.rates.sort((a: any, b: any) => {
              const lowerA = a.name?.toLowerCase() || '';
              const lowerB = b.name?.toLowerCase() || '';

              if (lowerA < lowerB) {
                return -1;
              }

              if (lowerA > lowerB) {
                return 1;
              }

              return 0;
            });
            bookingCreateModule.setAvailableRates(sorted);
          }
        }
      });
    } catch (e) {
      console.log('err', e);
      bookingCreateModule.setLoading(false);
    }
  }

  @Action
  async createHoldAndBooking() {
    try {
      bookingCreateModule.setLoading(true);
      bookingCreateModule.setCurrentBookingItem(0);
      const reqs: any[] = [];
      bookingCreateModule.generateCorrelationId();
      bookingCreateModule.ValidPassCodes.forEach((pass: ValidPass) => {
        const venueId = appModule.VenueId;
        bookingCreateModule.buildHold();
        pass.passCode;
        bookingCreateModule.buildBooking();
        const hold = bookingCreateModule.Hold;
        const booking: any = bookingCreateModule.Booking;
        const payload = { hold: hold, code: booking?.booking?.resellerBookingRef, booking: booking };
        const promise = bookingClient.createHoldAndBooking(venueId, payload).toPromise();
        bookingCreateModule.incrementCurrentBookingItem();
        reqs.push(promise);
      });
      await Promise.all(reqs)
        .then((data: any) => {
          bookingCreateModule.setLoading(false);
          if (!data) {
            // router.push({ name: constants.routes.names.HOME }).catch();
            // return;
          }

          bookingCreateModule.setBookingResponse(data);
          bookingConfirmationModule.setBookings(data);
          bookingConfirmationModule.chooseBookingIds();
        })
        .catch((e) => {
          console.log('create catch', e);
        });
    } catch (e) {
      console.log('err', e);
      bookingCreateModule.setLoading(false);
    }
  }

  @Action
  async getAttractionName() {
    try {
      passValidationModule.setPageLoading(true);
      const venueId = appModule.VenueId;
      const promise = bookingClient.attractionName(venueId).toPromise();
      await promise.then((response: any) => {
        if (response && _.isString(response.attraction)) {
          appModule.setAttractionName(response.attraction);
        }
        if (response && _.isString(response.instruction)) {
          appModule.setAttractionInstructions(response.instruction);
        }
      });
      passValidationModule.setPageLoading(false);
    } catch (e) {
      console.log('err', e);
      passValidationModule.setPageLoading(false);
    } finally {
      // Navigate to help page if attraction ID is invalid
      if (!appModule.AttractionName || appModule.AttractionName.length === 0) {
        router.push({ name: constants.routes.names.HELP }).catch();
        setTimeout(() => {
          appModule.setAlertBannerMessage(constants.messages.ALERT_INVALID_LINK);
          appModule.setAlertBannerActive(true);
        }, 500);
      }
    }
  }

  @Action
  async getCountries() {
    try {
      bookingCreateModule.setCountryListLoading(true);
      const promise = bookingClient.getCountries().toPromise();
      await promise.then((response: any) => {
        appModule.setCountryList(response);
      });
      bookingCreateModule.setCountryListLoading(false);
    } catch (e) {
      console.log('err', e);
      bookingCreateModule.setCountryListLoading(false);
    }
  }

  @Action
  async getVoucherImage(value: string) {
    try {
      const venueId = appModule.VenueId;
      return await voucherClient.getVoucherImage(value, venueId);
    } catch (e) {
      console.log('err', e);
    }
  }
}

export const httpModule = getModule(ModuleHttp, store);
