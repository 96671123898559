<template>
  <div>
    <div class="pre-line" v-if="SummaryText.length > 0">
      {{ SummaryText }}<a class="text-decoration-underline" @click="toggleShowFullText">{{ ShowFullTextButton }}</a>
    </div>
    <v-divider v-if="ShowDivider && SummaryText.length > 0" class="divider-spacing"></v-divider>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class RdmReadMore extends Vue {
  @Prop({ type: String, default: '', required: true })
  public text!: string;
  @Prop({ type: Boolean, default: false })
  public showDivider!: boolean;
  private showFullText = false;
  private maxCharacters = 140;

  toggleShowFullText() {
    this.showFullText = !this.showFullText;
  }

  get Text(): string {
    return this.text;
  }

  get ShowDivider(): boolean {
    return this.showDivider;
  }

  get SummaryText(): string {
    if (this.Text && this.Text.length > 0 && this.showFullText) {
      return this.Text;
    } else if (this.Text && this.Text.length > 0 && !this.showFullText) {
      return `${this.Text.substring(0, this.maxCharacters)}${this.Text.length > this.maxCharacters ? '...' : ''}`;
    }
    return '';
  }

  get ShowFullTextButton(): string {
    if (this.showFullText) {
      return 'Show Less';
    }
    return 'Read More';
  }
}
</script>

<style scoped>
.divider-spacing {
  margin-top: 24px;
  margin-bottom: 24px;
}
</style>
