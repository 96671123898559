import axios, { AxiosInstance } from 'axios';
import { AxiosErrorWithResponse, intercept } from '@/api/axiosInterceptors';
import { config } from '@/api/config';
import { OppApiError } from '@/api/errorHandlers';
import { catchError, from, map, Observable } from 'rxjs';
import { Logger } from '@/logger/logger';

export class BookingClient {
  private httpClient: AxiosInstance;

  /** logger */
  private logger = new Logger();

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.BookingApiUrl,
      timeout: 1000 * 60 * 5, // todo replace with config variable
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // this.httpClient.interceptors.request.use(intercept.Request);
    this.httpClient.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      },
    );

    this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(BookingClient.ErrorResponse));
  }

  private static ErrorResponse(err: AxiosErrorWithResponse) {
    // if (err.response?.status >= 400) {
    //   console.clear();
    // }

    if (err.response?.status === 401) {
      return BookingClient.on401(err);
    }

    if (err.response.status >= 400 && err.response.status < 500) {
      const wpsErr = err.response.data as OppApiError;
      // if (err.response.status === 403) {
      //   appModule.addMessageError(codes.GENERAL_MESSAGE_FORBIDDEN);
      // }
      return Promise.reject({
        status: err.response.status,
        wpsErr,
        ...err,
      });
    }

    // not 4xx
    return Promise.reject({
      status: err.response.status,
      ...err,
    });
  }

  private static on401(err: any): Promise<any> {
    return Promise.reject(err);
  }

  public handle401(on401: (err: any) => void) {
    BookingClient.on401 = (err: any) => {
      on401(err);
      return Promise.reject(err);
    };
  }

  public getIdentity(): Observable<any> {
    return from(this.httpClient.get<string>(`/identify`)).pipe(
      map((rs) => {
        return rs.data;
      }),
      catchError((e) => {
        return e;
      }),
    );
  }

  public getCountries(): Observable<any> {
    return from(this.httpClient.get<Array<any>>(`/countries`)).pipe(
      map((rs) => {
        return rs.data;
      }),
      catchError((e) => {
        return e;
      }),
    );
  }

  public searchBookings(code: string, s: string): Observable<any> {
    return from(this.httpClient.get<Array<any>>(`/search?code=${code}&s=${s}`)).pipe(
      map((rs) => {
        return rs.data;
      }),
      catchError((e) => {
        return e;
      }),
    );
  }

  public viewBookingCreate(s: string): Observable<any> {
    const date = new Date();
    return from(this.httpClient.get<any>(`/viewBookingCreate?s=${s}&ts=${date.toISOString()}`)).pipe(
      map((rs) => {
        return rs.data;
      }),
      catchError((e) => {
        return e;
      }),
    );
  }

  public attractionName(s: string): Observable<any> {
    return from(this.httpClient.get<any>(`/attractionName?terminalId=${s}`)).pipe(
      map((rs) => {
        return rs.data;
      }),
      catchError((e) => {
        return e;
      }),
    );
  }

  public createHoldAndBooking(s: string, payload: any): Observable<any> {
    return from(this.httpClient.post<any>(`/createHoldAndBooking?s=${s}`, payload)).pipe(
      map((rs) => {
        this.logger.debug(`createHoldAndBooking response: ${JSON.stringify(rs.data)}`);
        return rs.data;
      }),
      catchError((e) => {
        this.logger.error(`createHoldAndBooking failed with error: ${JSON.stringify(e)}`);
        return e;
      }),
    );
  }
}

const bookingClient = new BookingClient();
export default bookingClient;
