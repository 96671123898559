<template>
  <div class="text-align-center">
    <h1>404</h1>
    <h3>
      Looks like you are lost. You should head back
      <router-link to="/" color="info">Home</router-link>.
    </h3>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class NotFoundView extends Vue {}
</script>
