export const config = {
  get ApiUrl() {
    if (!process.env.WPS_URL) return '/api/wps';
    return process.env.WPS_URL;
  },
  get BackplaneApiUrl() {
    if (!process.env.VUE_BACKPLANE_API_URL) return '/api';
    return process.env.VUE_BACKPLANE_API_URL;
  },
  get CompanyApiUrl() {
    if (!process.env.VUE_APP_OPP_API_URL) return '/api/companies';
    return process.env.VUE_APP_OPP_API_URL;
  },
  get BookingApiUrl() {
    if (!process.env.VUE_APP_BOOKING_API_URL) return '/api/booking/';
    return process.env.VUE_APP_BOOKING_API_URL;
  },
  get GCApiUrl() {
    if (!process.env.VUE_APP_GC_API_URL) return '/api/gc/';
    return process.env.VUE_APP_GC_API_URL;
  },
  get InventoryApiUrl() {
    if (!process.env.VUE_APP_INV_API_URL) return '/api/inventory/';
    return process.env.VUE_APP_INV_API_URL;
  },
  get PosApiUrl() {
    if (!process.env.VUE_APP_POS_API_URL) return '/api/pos/';
    return process.env.VUE_APP_POS_API_URL;
  },
  get PricingApiUrl() {
    if (!process.env.VUE_APP_PRICING_API_URL) return '/api/';
    return process.env.VUE_APP_PRICING_API_URL;
  },
  get ResellersApiUrl() {
    if (!process.env.VUE_APP_COMPANY_API_URL) return '/api/resellers';
    return process.env.VUE_APP_COMPANY_API_URL;
  },
  get SuppliersApiUrl() {
    if (!process.env.VUE_APP_SUPPLIERS_API_URL) return '/api/suppliers';
    return process.env.VUE_APP_SUPPLIERS_API_URL;
  },
  get ProductsApiUrl() {
    if (!process.env.VUE_APP_PRODUCTS_API_URL) return '/api/products/';
    return process.env.VUE_APP_PRODUCTS_API_URL;
  },
  get RatesApiUrl() {
    if (!process.env.VUE_APP_RATES_API_URL) return '/api/rates/';
    return process.env.VUE_APP_RATES_API_URL;
  },
  get ReportingApiUrl() {
    if (!process.env.VUE_APP_REPORTS_API_URL) return '/api/reports';
    return process.env.VUE_APP_REPORTS_API_URL;
  },
  get VoucherApiUrl() {
    if (!process.env.VUE_APP_VOUCHER_API_URL) return '/api/voucher/';
    return process.env.VUE_APP_VOUCHER_API_URL;
  },
  get PriceScheduleOffsetBooking() {
    if (!process.env.PRICE_SCHEDULE_OFFSET_BOOKING) return '1';
    return process.env.PRICE_SCHEDULE_OFFSET_BOOKING;
  },
  get PriceScheduleOffsetPricing() {
    if (!process.env.PRICE_SCHEDULE_OFFSET_PRICING) return '365';
    return process.env.PRICE_SCHEDULE_OFFSET_PRICING;
  },
  get ApiTimeout() {
    if (!process.env.API_TIMEOUT) return '300000';
    return process.env.API_TIMEOUT;
  },
  get Env() {
    return process.env.VUE_APP_ENV;
  },
  get IsProductionEnv() {
    return this.Env === 'production';
  },
  get IsLocalEnv() {
    return this.Env === 'local';
  },
  get Version() {
    return process.env.VUE_APP_VERSION;
  },
  get GoogleAnalyticsTagId() {
    if (!process.env.VUE_APP_GOOGLE_TAG_ID) return 'G-N698W06L3P';
    return process.env.VUE_APP_GOOGLE_TAG_ID;
  },

  Validate() {
    if (!this.ApiUrl) {
      throw new Error('No WPS_URL env variable set');
    }
    if (!this.BackplaneApiUrl) {
      throw new Error('No VUE_BACKPLANE_API_URL env variable set');
    }
    if (!this.Env) {
      throw new Error('No VUE_APP_ENV env variable set');
    }
    if (!this.Version) {
      throw new Error('No VUE_APP_VERSION env variable set');
    }
  },
};
