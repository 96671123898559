import axios, { AxiosInstance } from 'axios';
import { AxiosErrorWithResponse, intercept } from '@/api/axiosInterceptors';
import { config } from '@/api/config';
import { OppApiError } from '@/api/errorHandlers';
import { catchError, from, map, Observable } from 'rxjs';

export class GoCityClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.GCApiUrl,
      timeout: 1000 * 60 * 5, // todo replace with config variable
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // this.httpClient.interceptors.request.use(intercept.Request);
    this.httpClient.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      },
    );

    this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(GoCityClient.ErrorResponse));
  }

  private static ErrorResponse(err: AxiosErrorWithResponse) {
    // if (err.response?.status >= 400) {
    //   console.clear();
    // }

    if (err.response?.status === 401) {
      return GoCityClient.on401(err);
    }

    if (err.response.status >= 400 && err.response.status < 500) {
      const wpsErr = err.response.data as OppApiError;
      // if (err.response.status === 403) {
      //   appModule.addMessageError(codes.GENERAL_MESSAGE_FORBIDDEN);
      // }
      return Promise.reject({
        status: err.response.status,
        wpsErr,
        ...err,
      });
    }

    // not 4xx
    return Promise.reject({
      status: err.response.status,
      ...err,
    });
  }

  private static on401(err: any): Promise<any> {
    return Promise.reject(err);
  }

  public handle401(on401: (err: any) => void) {
    GoCityClient.on401 = (err: any) => {
      on401(err);
      return Promise.reject(err);
    };
  }

  /**
   * getPassUsage calls the server layer to check to see if the pass is valid
   * @param passID
   * @param terminalId
   */
  public getPassUsage(passID: string, terminalId: string): Observable<any> {
    return from(this.httpClient.get<Array<string>>(`/passusage/${passID}?terminal=${terminalId}`)).pipe(
      map((rs) => {
        return rs.data;
      }),
      catchError((e) => {
        return e;
      }),
    );
  }
}

const goCityClient = new GoCityClient();
export default goCityClient;
