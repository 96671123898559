import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { config } from '@/api/config';
import { ApiError, StatusEnum } from '@/api/errorHandlers';
import { v4 as uuidv4 } from 'uuid';

const headerRequestID = 'X-Request-ID';

export interface AxiosErrorWithResponse<T = any> extends AxiosError<T> {
  response: AxiosResponse<T>;
}

export const intercept = {
  Request(config: AxiosRequestConfig) {
    if (config && config.headers && !config.headers[headerRequestID]) {
      config.headers[headerRequestID] = uuidv4(); // todo replace with custom uuid function
    }
    return config;
  },

  Response(response: AxiosResponse) {
    if (config.IsLocalEnv) {
      //console.log('Response: ', JSON.stringify(response, null, 2));
    }

    return response;
  },

  Error(responseErrorHandler: (err: AxiosErrorWithResponse) => Promise<any>): (e: any) => Promise<any> {
    return (e: any) => {
      // prettier-ignore

      if (axios.isCancel(e)) {
                const err: ApiError = {
                    ...e,
                    message: "Request cancelled",
                    status: StatusEnum.CANCELLED,
                    isAxiosError: false,
                    toJSON: function (): object {
                        throw new Error("Function not implemented.");
                    },
                    name: ""
                };
                return Promise.reject(err);
            }

      if (axios.isAxiosError(e)) {
        const err = e as AxiosError;

        // The request was made and the server responded with not a 2xx status code
        if (err.response) {
          return responseErrorHandler(err as AxiosErrorWithResponse);
        } else {
          // prettier-ignore

          if (err.code === 'ECONNABORTED') {
                        const e: ApiError = {
                            status: StatusEnum.ABORTED,
                            ...err,
                        };
                        return Promise.reject(e);
                    }

          if (err.code === null) {
            const e: ApiError = {
              status: StatusEnum.NETWORK_FAILED,
              ...err,
            };
            return Promise.reject(e);
          }
        }
      }

      e.status = StatusEnum.UNKNOWN;
      return Promise.reject(e);
    };
  },
};
