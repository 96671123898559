<template>
  <div>
    <v-row>
      <v-col>
        <div class="display-1 mb-3">Contact</div>
        <div>
          For help, call us toll-free within the US at
          <a class="nowrap" href="tel:+18008879103">+1 (800) 887-9103</a> or, email us on this page:
          <a class="nowrap" target="_blank" href="https://gocity.com/las-vegas/en-us/faqs"
            >https://gocity.com/las-vegas/en-us/faqs</a
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class HelpView extends Vue {}
</script>
