import store from '@/store';
import _ from 'lodash';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ValidPass } from '@/models';

@Module({
  dynamic: true,
  store,
  name: 'rdm-gc-booking-confirmation',
  namespaced: true,
})
class ModuleBookingConfirmation extends VuexModule {
  private supplier = '';
  private bookings = [] as Array<any>;
  private suppliers = [] as Array<any>;
  private selectedSupplier = {} as any;
  private products = [] as Array<any>;
  private selectedProduct = {} as any;
  private validPasses = [] as Array<ValidPass>;
  private bookingIds = [] as Array<string>;
  private loading = false;

  get Supplier() {
    return this.supplier;
  }

  get Bookings() {
    return this.bookings;
  }

  get Suppliers() {
    return this.suppliers;
  }

  get SelectedSupplier() {
    return this.selectedSupplier;
  }

  get Products() {
    return this.products;
  }

  get SelectedProduct() {
    return this.selectedProduct;
  }

  get ValidPasses() {
    return this.validPasses;
  }

  get BookingIds() {
    return this.bookingIds;
  }

  get Loading() {
    return this.loading;
  }

  @Mutation
  setSupplier(supplier: string) {
    this.supplier = supplier;
  }

  @Mutation
  setBookings(bookings: Array<any>) {
    this.bookings = bookings;
  }

  @Mutation
  setSuppliers(suppliers: Array<any>) {
    this.suppliers = suppliers;
  }

  @Mutation
  setSelectedSupplier(supplier: any) {
    this.selectedSupplier = supplier;
  }

  @Mutation
  setProducts(products: Array<any>) {
    this.products = products;
  }

  @Mutation
  setSelectedProduct(product: any) {
    this.selectedProduct = product;
  }

  @Mutation
  setValidPasses(passes: Array<ValidPass>) {
    this.validPasses = passes;
  }

  @Mutation
  setBookingIds(bookingIds: Array<string>) {
    this.bookingIds = bookingIds;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Action
  appendBooking(booking: any) {
    this.bookings.push(booking);
  }

  @Action
  appendBookingId(id: string) {
    this.bookingIds.push(id);
  }

  @Action
  filterBookingsByBookingId(bookingIds: Array<string>) {
    const filteredBookings = [] as Array<any>;
    bookingIds.forEach((bId) => {
      const i = this.bookings.findIndex((b) => b.bookingId === bId);
      if (i > -1) {
        filteredBookings.push(this.bookings[i]);
      }
    });
    this.setBookings(filteredBookings);
  }

  @Action
  chooseBookingIds() {
    if (Array.isArray(this.bookings)) {
      this.bookings.forEach((booking) => {
        if (
          booking.payload &&
          booking.payload.booking &&
          booking.payload.booking.booking &&
          _.isString(booking.payload.booking.booking.id)
        ) {
          this.appendBookingId(booking.payload.booking.booking.id);
        }
      });
    }
  }

  @Action
  chooseSupplierProduct() {
    if (this.bookings.length > 0) {
      const iSupplier = this.Suppliers.findIndex((s) => s.id === this.bookings[0].supplierId);
      if (iSupplier > -1) {
        this.setSelectedSupplier(this.Suppliers[iSupplier]);
      }
      const iProduct = this.Products.findIndex((p) => p.id === this.bookings[0].productId);
      if (iProduct > -1) {
        this.setSelectedProduct(this.Products[iProduct]);
      }
    }
  }

  @Action
  clearState() {
    this.setSupplier('');
    this.setBookings([]);
    this.setSuppliers([]);
    this.setSelectedSupplier({});
    this.setProducts([]);
    this.setSelectedProduct({});
    this.setValidPasses([] as Array<ValidPass>);
    this.setBookingIds([]);
    this.setLoading(false);
  }
}

export const bookingConfirmationModule = getModule(ModuleBookingConfirmation, store);
