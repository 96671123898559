<template>
  <div>
    <div class="display-1 mb-1">
      {{ title }}
    </div>
    <rdm-read-more v-if="IsValidationRoute" :text="AttractionInstructions" show-divider></rdm-read-more>
    <div v-if="subtitle && subtitle.length > 0" class="mb-1">
      {{ subtitle }}
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { appModule } from '@/store/modules/moduleApp';
import RdmReadMore from '@/components/common/RdmReadMore.vue';
import { constants } from '@/helpers/constants';

@Component({
  components: { RdmReadMore },
})
export default class GoCityHeader extends Vue {
  @Prop({ type: String, default: '', required: true })
  public title!: string;

  @Prop({ type: String, default: '' })
  public subtitle!: string;

  get VenueId(): string {
    return appModule.VenueId;
  }

  get AttractionInstructions(): string {
    return appModule.AttractionInstructions;
  }

  get IsValidationRoute(): boolean {
    return this.$route.name === constants.routes.names.PASS_VALIDATION;
  }
}
</script>
<style lang="scss">
.pre-line {
  white-space: pre-line;
}
</style>
