import store from '@/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { v4 as uuidv4 } from 'uuid';
import { ContactDetail, ValidPass } from '@/models';

@Module({
  dynamic: true,
  store,
  name: 'rdm-gc-booking-create',
  namespaced: true,
})
class ModuleBookingCreate extends VuexModule {
  private bookingStep = 1;
  private currentBookingItem = 0;
  private validPassCodes = [] as Array<ValidPass>;
  private loading = false;
  private countryListLoading = false;
  private selectedSupplierProduct = null;
  private selectedRate = '';
  private availableRates = [] as Array<any>;
  private contactList = [] as Array<ContactDetail>;
  private hold = null;
  private booking = null;
  private bookingResponse = null;
  private bookingCorrelationId = '';

  get BookingStep() {
    return this.bookingStep;
  }

  get CurrentBookingItem() {
    return this.currentBookingItem;
  }

  get ValidPassCodes() {
    return this.validPassCodes;
  }

  get SelectedSupplierProduct() {
    return this.selectedSupplierProduct;
  }

  get SelectedRate() {
    return this.selectedRate;
  }

  get AvailableRates() {
    return this.availableRates;
  }

  get Hold() {
    return this.hold;
  }

  get Booking() {
    return this.booking;
  }

  get ContactList() {
    return this.contactList;
  }

  get BookingResponse() {
    return this.bookingResponse;
  }

  get BookingCorrelationId() {
    return this.bookingCorrelationId;
  }

  get Loading() {
    return this.loading;
  }

  get CountryListLoading() {
    return this.countryListLoading;
  }

  @Mutation
  setBookingStep(step: number) {
    this.bookingStep = step;
  }

  @Mutation
  setCurrentBookingItem(item: number) {
    this.currentBookingItem = item;
  }

  @Mutation
  setValidPassCodes(passCodes: Array<ValidPass>) {
    this.validPassCodes = passCodes;
  }

  @Mutation
  setSelectedSupplierProduct(supplierProduct: any) {
    this.selectedSupplierProduct = supplierProduct;
  }

  @Mutation
  setSelectedRate(rate: string) {
    this.selectedRate = rate;
  }

  @Mutation
  setAvailableRates(rates: Array<any>) {
    this.availableRates = rates;
  }

  @Mutation
  setHold(hold: any) {
    this.hold = hold;
  }

  @Mutation
  setBooking(booking: any) {
    this.booking = booking;
  }

  @Mutation
  setContactList(contactList: Array<ContactDetail>) {
    this.contactList = contactList;
  }

  @Mutation
  setBookingResponse(booking: any) {
    this.bookingResponse = booking;
  }

  @Mutation
  setBookingCorrelationId(bookingCorrelationId: string) {
    this.bookingCorrelationId = bookingCorrelationId;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setCountryListLoading(loading: boolean) {
    this.countryListLoading = loading;
  }

  @Action
  nextBookingStep() {
    this.setBookingStep(this.bookingStep + 1);
  }
  @Action
  previousBookingStep() {
    this.setBookingStep(this.bookingStep - 1);
  }

  @Action
  incrementCurrentBookingItem() {
    this.setCurrentBookingItem(this.currentBookingItem + 1);
  }

  @Action
  generateCorrelationId() {
    this.setBookingCorrelationId(uuidv4());
  }

  @Action
  buildHold() {
    const hold = { hold: {}, meta: { reqId: uuidv4() } };
    const contact = this.contactList[this.currentBookingItem];
    const i = this.availableRates.findIndex((r) => r.priceId === contact.rate); // todo changed from rate ID to price ID
    const rate = this.availableRates[i];
    const supplierProduct: any = this.selectedSupplierProduct;
    hold.hold = {
      id: uuidv4(),
      items: [
        {
          at: rate.start,
          availabilityId: rate.availabilityId,
          quantity: 1,
          rateId: rate.id,
          supplierId: supplierProduct ? supplierProduct.supplierId : '',
          productId: supplierProduct ? supplierProduct.productId : '',
          priceId: rate.priceId,
          travelerType: rate.ageBand,
        },
      ],
      partnerId: rate.partnerId,
    };
    this.setHold(hold);
  }

  @Action
  buildBooking() {
    const correlationId = this.BookingCorrelationId;
    const contact = this.contactList[this.currentBookingItem];
    const pass = this.validPassCodes[this.currentBookingItem];
    const hold: any = this.hold;
    const booking = { booking: {} };
    booking.booking = {
      customer: {
        email: contact.email?.trim(),
        firstName: contact.firstName?.trim(),
        lastName: contact.lastName?.trim(),
        phone: contact.phone?.trim(),
        country: contact.countryCode || 'US',
      },
      resellerBookingRef: pass.passCode,
      holdId: hold ? hold.hold.id : '',
      items: [
        {
          // "id": uuidv4(),
          priceId: hold ? hold.hold.items[0].priceId : '',
          productId: hold ? hold.hold.items[0].productId : '',
          rateId: hold ? hold.hold.items[0].rateId : '',
          startTime: hold ? hold.hold.items[0].at : '',
          supplierId: hold ? hold.hold.items[0].supplierId : '',
          availabilityId: hold ? hold.hold.items[0].availabilityId : '',
          quantity: 1,
          traveler: {
            email: contact.email?.trim(),
            firstName: contact.firstName?.trim(),
            lastName: contact.lastName?.trim(),
            phone: contact.phone?.trim(),
            isLead: true,
            type: hold ? hold.hold.items[0].travelerType : '',
          },
        },
      ],
      partnerId: hold ? hold.hold.partnerId : '',
      ext: {
        'x-gocity.OrderID': correlationId,
      },
    };
    this.setBooking(booking);
  }

  @Action
  clearState() {
    this.setBookingStep(1);
    this.setCurrentBookingItem(0);
    this.setValidPassCodes([] as Array<ValidPass>);
    this.setSelectedSupplierProduct(null);
    this.setSelectedRate('');
    this.setAvailableRates([]);
    this.setHold(null);
    this.setBooking(null);
    this.setContactList([]);
    this.setLoading(false);
    this.setCountryListLoading(false);
  }
}

export const bookingCreateModule = getModule(ModuleBookingCreate, store);
