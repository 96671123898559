<template>
  <div>
    <v-skeleton-loader v-if="PageLoading" class="min-width-350" type="article, actions"></v-skeleton-loader>
    <template v-else>
      <go-city-header :title="Title" :subtitle="subtitle"></go-city-header>
      <v-form v-model="passIdValid" ref="passIdForm" @submit.prevent lazy-validation>
        <template v-for="(p, i) of PassList">
          <v-row :key="`guest-pass-${i}`">
            <v-col class="pb-0">
              <strong>Guest {{ i + 1 }} Pass ID</strong>
              <v-text-field
                outlined
                background-color="white"
                placeholder="Enter pass ID here"
                type="number"
                :append-outer-icon="PassList.length > 1 ? 'mdi-close' : ''"
                hide-details
                :rules="activeValidation ? requiredField : []"
                v-model.trim="PassList[i]"
                class="numeric-input"
                @click:append-outer="removeGuest(i)"
                @keyup.enter="validate"
                @keydown="handleKeyDown"
              >
              </v-text-field>
              <template v-if="showPassValidation && PassList.length === PassResultList.length">
                <div v-if="PassResultList[i].status === 200">
                  <v-icon color="success" class="">mdi-check-circle</v-icon>
                  <span class="offset-left-8 success--text">ID Valid</span>
                </div>
                <div v-else>
                  <v-icon color="error" class="">mdi-close-circle</v-icon>
                  <span
                    v-if="PassResultList[i].msg && PassResultList[i].msg.length > 0"
                    class="offset-left-8 error--text"
                    >{{ PassResultList[i].msg }}</span
                  >
                  <span v-else class="offset-left-8 error--text"
                    >The ID you entered was incorrect. Please try again or contact us for assistance. You may continue
                    with valid IDs.</span
                  >
                </div>
              </template>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              v-if="GuestCount < maxGuestCount"
              text
              color="primary"
              @click="addGuest"
              :disabled="GuestCount >= maxGuestCount || Loading"
            >
              <v-icon>mdi-plus</v-icon>
              Add More IDs</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn color="primary" outlined @click="searchBookings" :disabled="GuestCount >= maxGuestCount || Loading"
              >View Tickets</v-btn
            >
            <div class="flex-grow-1"></div>
            <v-btn
              v-bind:class="{ 'offset-right-30': PassList.length > 1 }"
              color="primary"
              elevation="0"
              :loading="Loading"
              @click="validate"
              >Book Tickets</v-btn
            >
          </v-col>
        </v-row>
        <v-row v-if="GuestCount >= maxGuestCount">
          <v-col>
            <span
              >You can enter a maximum of nine pass numbers at a time. If you would like to add more, simply submit your
              first request and repeat the process.</span
            >
          </v-col>
        </v-row>
      </v-form>
    </template>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import GoCityHeader from '@/components/common/GoCityHeader.vue';
import { appModule } from '@/store/modules/moduleApp';
import { constants } from '@/helpers/constants';
import { passValidationModule } from '@/store/modules/modulePassValidation';
import _ from 'lodash';
import { requiredFieldRules } from '@/helpers/validations';
import { httpModule } from '@/store/modules/moduleHttp';
import { PassValidationResponse, ValidPass } from '@/models';
import { bookingCreateModule } from '@/store/modules/moduleBookingCreate';
import { handleKeyDownOnlyNumeric } from '@/helpers/commonFunctions';
import { bookingListModule } from '@/store/modules/moduleBookingList';

@Component({
  components: {
    GoCityHeader,
  },
})
export default class ValidationView extends Vue {
  private title = constants.messages.HOME_PAGE_TITLE;
  private subtitle = constants.messages.HOME_PAGE_SUBTITLE;
  private maxGuestCount = constants.app.MAX_GUEST_PASS_COUNT;
  private attractionStorageKey = constants.app.ATTRACTION_ID;
  private requiredField = requiredFieldRules;
  private activeValidation = false;
  private passIdValid = false;
  private showPassValidation = true;
  private handleKeyDown = handleKeyDownOnlyNumeric;

  mounted() {
    if (this.$route.params && !_.isEmpty(this.$route.params.venueId)) {
      appModule.setVenueId(this.$route.params.venueId);
      // appModule.setAlertBannerActive(false);
      // appModule.setAlertBannerMessage('');
      localStorage.setItem(this.attractionStorageKey, this.$route.params.venueId);
      httpModule.getAttractionName();
    }
  }

  destroyed() {
    passValidationModule.clearState();
    appModule.dismissAlertBanner();
  }
  showAlert() {
    appModule.setAlertBannerActive(true);
  }

  addGuest() {
    passValidationModule.addGuestCount();
    passValidationModule.addPassToList('');
  }

  removeGuest(i: number) {
    if (this.PassList.length <= 1) {
      return;
    }

    passValidationModule.removeGuestCount(); // todo change this to remove a specific guest, instead of the last
    passValidationModule.removeItemFromPassList(i);
  }

  normalizePassList(): Array<string> {
    const normalized = [] as Array<string>;
    const passes = _.cloneDeep(this.PassList);
    const numberRegex = /[^0-9]/g;
    passes.forEach((p) => {
      normalized.push(p.replace(numberRegex, ''));
    });
    return normalized;
  }

  deDupePassList(passes: Array<string>): Array<string> {
    const p = _.cloneDeep(passes);
    return _.uniq(p);
  }

  searchBookings() {
    this.activeValidation = true;
    if (this.$refs?.passIdForm) {
      const form: any = this.$refs.passIdForm;
      const isValid = form.validate();
      if (isValid) {
        const normalizedPasses = this.normalizePassList();
        const deDupedPasses = this.deDupePassList(normalizedPasses);
        if (deDupedPasses.length > 0 && deDupedPasses[0].length > 0) {
          bookingListModule.setValidPassCodes(deDupedPasses);
          if (this.$route.name !== constants.routes.names.BOOKING_LIST) {
            this.$router.push({ name: constants.routes.names.BOOKING_LIST }).catch();
          }
        }
      }
    }
  }

  validate() {
    /**
     * - Call validate function;
     * - Display error alert if any poss codes invalid
     * - If bookings are not already associated to a booking, navigate to booking create if all are valid;
     * - If bookings are already associated to a booking, navigate to booking details if all are valid;
     */
    this.activeValidation = true;
    if (this.$refs?.passIdForm) {
      const form: any = this.$refs.passIdForm;
      const isValid = form.validate();
      if (isValid) {
        const normalizedPasses = this.normalizePassList();
        const deDupedPasses = this.deDupePassList(normalizedPasses);
        passValidationModule.setPassList(deDupedPasses);
        if (deDupedPasses.length > 0 && deDupedPasses[0].length > 0) {
          httpModule.getPassValid(deDupedPasses).then(() => {
            if (this.HasInvalidPass) {
              appModule.setAlertBannerActive(true);
              appModule.setAlertBannerMessage(constants.messages.ALERT_INCORRECT_ID);
              this.showPassValidation = true;
            } else if (this.PassResultCreationList.length > 0) {
              const validPasses = [] as Array<ValidPass>;
              this.PassResultCreationList.forEach((p) => {
                let travelerType = p.passType;
                const displayTravelerType = p.passType;
                const venueId = appModule.VenueId;
                if (venueId === '113244') {
                  travelerType = 'ADULT';
                }
                validPasses.push({
                  passCode: p.code,
                  travelerType: travelerType,
                  displayTravelerType: displayTravelerType,
                });
              });
              bookingCreateModule.setValidPassCodes(validPasses);
              this.goToBookingCreate();
            }
            // else if (this.PassResultDetailList.length > 0) {
            //   // todo remove go to booking detail
            //   bookingDetailModule.setValidPassCodes(_.map(this.PassResultDetailList, 'code'));
            //   this.goToBookingDetails();
            // }
            else {
              this.showPassValidation = true;
            }
          });
        }
      }
    }
  }

  goToBookingCreate() {
    if (this.$route.name !== constants.routes.names.BOOKING_CREATE) {
      this.$router.push({ name: constants.routes.names.BOOKING_CREATE }).catch();
    }
  }

  goToBookingDetails() {
    if (this.$route.name !== constants.routes.names.BOOKING_DETAIL) {
      this.$router.push({ name: constants.routes.names.BOOKING_DETAIL }).catch();
    }
  }

  get GuestCount() {
    return passValidationModule.GuestCount;
  }

  get PassList(): Array<string> {
    return passValidationModule.PassList;
  }

  set PassList(passList: Array<string>) {
    passValidationModule.setPassList(passList);
  }

  get PassResultList(): Array<PassValidationResponse> {
    return passValidationModule.PassResultList;
  }

  get PassResultCreationList(): Array<PassValidationResponse> {
    return passValidationModule.PassResultCreationList;
  }

  get AppAlertBanner(): boolean {
    return appModule.AlertBannerActive;
  }

  get HasInvalidPass(): boolean {
    return passValidationModule.HasInvalidPass;
  }

  get AttractionName(): string {
    return appModule.AttractionName;
  }

  get Title(): string {
    if (this.AttractionName && this.AttractionName.length > 0) {
      return `Redeem your ${this.AttractionName} tickets`;
    }
    return this.title;
  }

  get PageLoading(): boolean {
    return passValidationModule.PageLoading;
  }

  get Loading(): boolean {
    return passValidationModule.Loading;
  }
}
</script>
<style lang="scss">
.offset-right-30 {
  margin-right: 30px;
}

.offset-left-8 {
  margin-left: 8px;
}

.no-background .v-skeleton-loader__bone {
  background: transparent !important;
}
</style>
