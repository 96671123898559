import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const options = {
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#1A8890',
        secondary: '#24CAC2',
        accent: '#D6D5E3',
        error: '#E91A32',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        text: '#313846',
        background: '#f0efeb',
        backgroundGrey: '#E8E8E8',
        disabledGrey: '#BCBCBC',
      },
    },
    icons: {
      iconfont: 'mdi',
    },
  },
};

export default new Vuetify(options);
