/**
 * LogLevel - the different logging levels
 */
export enum LogLevel {
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
}

/**
 * LogRecord - contains basic log information
 */
export interface LogRecord {
  severity: string;
  timestamp: string;
  message: string;
  metadata?: Record<string, any>;
}

/**
 * HTTPLogRecord - a detailed log record of the HTTP request and response
 */
export interface HTTPLogRecord {
  severity: string;
  timestamp: string;
  message: string;
  method: string;
  host: string;
  url: string;
  statusCode: number;
  duration?: string;
  requestBody?: string;
  responseBody?: string;
}
