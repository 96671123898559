import store from '@/store';
import _ from 'lodash';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
  dynamic: true,
  store,
  name: 'rdm-gc-app',
  namespaced: true,
})
class ModuleApp extends VuexModule {
  private alertBannerActive = false;
  private alertBannerMessage = '';
  private venueId = '';
  private attractionName = '';
  private attractionInstructions = '';
  private countryList = [] as Array<any>;

  get AlertBannerActive() {
    return this.alertBannerActive;
  }

  get AlertBannerMessage() {
    return this.alertBannerMessage;
  }

  get VenueId() {
    return this.venueId;
  }

  get AttractionName() {
    return this.attractionName;
  }

  get AttractionInstructions() {
    return this.attractionInstructions;
  }

  get HasVenueId() {
    return !_.isEmpty(this.venueId);
  }

  get CountryList() {
    return this.countryList;
  }

  @Mutation
  setAlertBannerActive(active: boolean) {
    this.alertBannerActive = active;
  }

  @Mutation
  setAlertBannerMessage(message: string) {
    this.alertBannerMessage = message;
  }

  @Mutation
  setVenueId(venueId: string) {
    this.venueId = venueId;
  }

  @Mutation
  setAttractionName(attraction: string) {
    this.attractionName = attraction;
  }

  @Mutation
  setAttractionInstructions(instruction: string) {
    this.attractionInstructions = instruction;
  }

  @Mutation
  setCountryList(countries: Array<any>) {
    this.countryList = countries;
  }

  @Action
  toggleAlertBannerActive() {
    this.alertBannerActive = !this.alertBannerActive;
  }

  @Action
  dismissAlertBanner() {
    this.setAlertBannerActive(false);
    this.setAlertBannerMessage('');
  }
}

export const appModule = getModule(ModuleApp, store);
